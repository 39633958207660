/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, useTheme, Grid, Typography } from '@mui/material';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styled from 'styled-components';

const CalcProdForm = ({ produtividade, setProdutividade, close }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [lotacao, setLotacao] = React.useState(2.5);
  const [gmd, setGmd] = React.useState(0.9);
  const [pastejo, setPastejo] = React.useState(134);
  const [novaProdutividade, setNovaProdutividade] = useState(null);

  const InputField = styled.input`
    padding: 0.5em;
    border: 1px solid #ccc;
    border-radius: 3px;
    width: 100%;
  `;

  const handleClickOpen = () => {
    open === false ? setOpen(true) : setOpen(false);
  };

  function calcular() {
    setNovaProdutividade(gmd * lotacao * pastejo);
  }
  function cancelar() {
    setNovaProdutividade(null);
  }
  function confirmar() {
    setProdutividade(novaProdutividade);
    close();
  }

  return (
    <Grid container xs={12} border="1px solid grey">
      <Button
        href="#"
        variant="text"
        onClick={handleClickOpen}
        color="secondary"
      >
        Deseja calcular a sua Produtividade? <ArrowDropDownIcon />
      </Button>

      {open && (
        <Grid container xs={12} justifyContent="space-between">
          <Grid container xs={12}>
            <Grid container xs={6} mt={2}>
              <label>Lotação animal (animais/hectare): </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <InputField
                type={'number'}
                value={lotacao}
                onChange={(e) =>
                  setLotacao(parseFloat(e.target.value.replace('-', '')))
                }
              />
            </Grid>

            <Grid container xs={6} mt={2}>
              <label>GMD (kg/hectare): </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <InputField
                type={'number'}
                value={gmd}
                onChange={(e) =>
                  setGmd(parseFloat(e.target.value.replace('-', '')))
                }
              />
            </Grid>
            <Grid container xs={6} mt={2}>
              <label>Dias de pastejo: </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <InputField
                type={'number'}
                value={pastejo}
                onChange={(e) =>
                  setPastejo(parseFloat(e.target.value.replace('-', '')))
                }
              />
            </Grid>

            {novaProdutividade && (
              <Grid>
                <Typography>
                  A sua produtividade é de{' '}
                  <Typography
                    sx={{
                      display: 'inline-block',
                      color: theme.palette.primary.main,
                    }}
                  >
                    {novaProdutividade}
                  </Typography>{' '}
                  kg de peso vivo / hectare. Confirma alteração?
                </Typography>
                <Button onClick={cancelar} autoFocus>
                  Cancelar
                </Button>
                <Button onClick={confirmar} autoFocus>
                  Confirmar
                </Button>
              </Grid>
            )}

            <Grid xs={12} padding="1rem 1rem">
              <Button
                onClick={calcular}
                variant="contained"
                sx={{
                  width: '100%',
                  backgroundColor: theme.palette.warning.main,
                  color: '#ffffff',
                }}
              >
                <CalculateRoundedIcon /> Calcular a produtividade por hectare
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CalcProdForm;
