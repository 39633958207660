const { Grid, Typography } = require('@mui/material');

const DescCenarios = () => {
  return (
    <Grid padding={2}>
      <Typography variant="h6" xs={12} textAlign="center">
        CONFIRA OS CENÁRIOS ABAIXO
      </Typography>
      <Typography xs={12}>
        Foram elaborados três cenários com o objetivo de estimar o lucro
        potencial dessa cultivar, considerando diferentes valores de custo,
        preço e produtividade. Note que em cada cenário , dois parâmetros variam
        e um permanece constante.
      </Typography>
    </Grid>
  );
};

export default DescCenarios;
