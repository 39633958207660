/* eslint-disable react/prop-types */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { QuestionMarkRounded } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import CalcCustoForm from './calcCustoForm';

export default function AjudaCusto({ custo, setCusto }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        href="#"
        variant="text"
        onClick={handleClickOpen}
        color="secondary"
      >
        Ajuda para cálculo do custo
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          display="flex"
          justifyContent="center"
          gap={1}
          alignItems="center"
        >
          <QuestionMarkRounded />
          <Typography variant="h5">Calculo de Custo</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1">
              O valor do custo obtém-se através da soma dos valores padrões
              gastos por hectare com os seguintes itens:
            </Typography>
            <Typography variant="caption">
              adubação de base = R$ 400,00
              <br />
              adubação nitrogenada = R$ 250,00
              <br />
              calcário = R$ 0,00
              <br />
              combustíveis = R$ 150,00
              <br />
              defensivos agrícolas = R$ 100,00
              <br />
              sementes = R$ 100,00
              <br />
              serviços mecanizados = R$ 150,00
              <br />
              outros = R$ 0,00
            </Typography>
            <Grid xs={12}>
              <CalcCustoForm
                custo={custo}
                setCusto={setCusto}
                close={handleClose}
              />
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
