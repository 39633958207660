import { useMemo } from 'react';
import chroma from 'chroma-js';

function useCellColor(value, min, max) {
  // Definição das cores
  const red = '#f7696b'; // Vermelho para o valor mais negativo
  const orange = '#f8af79'; // Laranja para o meio do caminho nos negativos
  const yellow = '#ede585'; // Amarelo para zero e meio do caminho nos positivos
  const green = '#62be7a'; // Verde para o valor mais positivo

  const color = useMemo(() => {
    const normalized = (value - min) / (max - min);

    let scale;
    if (value < 0) {
      const negativeNormalized = normalized * (max / -min);
      scale = chroma.scale([red, orange, yellow]).mode('lrgb');
      return scale(negativeNormalized).hex();
    } else {
      const positiveNormalized = value / max;
      scale = chroma.scale([yellow, green]).mode('lrgb');
      return scale(positiveNormalized).hex();
    }
  }, [value, min, max]);

  return color;
}

export default useCellColor;
