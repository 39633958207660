import { BuildCircleRounded } from '@mui/icons-material';
import { AppBar, useTheme, Grid, Typography, Button } from '@mui/material';
import embrapa from 'assets/embrapa.png';
import About from 'components/About';
import { ConfigContext } from 'context';
import { useContext } from 'react';
/* import Feedback from 'components/Feedback'; */

const Navbar = () => {
  const theme = useTheme();
  const { toggleConfigOpen } = useContext(ConfigContext);

  const handleClickOpen = (e) => {
    e.preventDefault();
    toggleConfigOpen();
  };

  return (
    <AppBar
      sx={{
        position: 'static',
        background: theme.palette.primary.main,
        color: '#ffffff',
        boxShadow: 'none',
        fontSize: '1.25rem',
        fontFamily: 'Roboto, sans-serif',
      }}
    >
      <Grid
        container
        xs={12}
        padding="1rem"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid
          container
          xs={12}
          sm={8}
          justifyContent="flex-start"
          gap="0.5rem"
          alignItems="center"
        >
          <Grid display="flex" xs={12} sm={1} lg={3} justifyContent="center">
            <img src={embrapa} alt="logo embrapa" width={'50%'} />
          </Grid>

          <Grid container xs={12} sm={8} lg={8}>
            <Typography variant="h6">
              Você quer conhecer o lucro por hectare da cultivar BRS Estribo de
              capim-sudão?
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          sm={4}
          lg={4}
          gap="1rem"
          textTransform="uppercase"
          fontSize="1rem"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            href="#"
            variant="outlined"
            startIcon={<BuildCircleRounded />}
            sx={{
              backgroundColor: theme.palette.primary.main,
              ':hover': {
                backgroundColor: theme.palette.primary.light,
              },
              color: '#ffffff',
            }}
            onClick={handleClickOpen}
          >
            CONFIGURAÇÕES
          </Button>
          {/* <Feedback /> */}
          <About />
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Navbar;
