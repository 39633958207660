import * as React from 'react';
import Button from '@mui/material/Button';
import { DownloadRounded } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import FeatureNotReadyDialog from 'components/FeatureNotReady';

const DownloadResult = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  function handleClose() {
    setOpen(false);
  }
  return (
    <React.Fragment>
      <Button
        href="#"
        variant="outlined"
        startIcon={<DownloadRounded />}
        sx={{
          backgroundColor: theme.palette.primary.main,
          ':hover': {
            backgroundColor: theme.palette.primary.light,
          },
          color: '#ffffff',
        }}
        onClick={handleClickOpen}
      >
        Baixar Laudo
      </Button>
      <FeatureNotReadyDialog isOpen={open} handleClose={handleClose} />
    </React.Fragment>
  );
};

export default DownloadResult;
