/* eslint-disable react/prop-types */
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import getAssessment from 'components/Assessment';
/* import chroma from 'chroma-js'; */
import util from 'helpers/util';
import useCellColor from 'hooks/useCellColor';

const Resultado = ({
  sortedResults,
  rowText,
  colText,
  resultados,
  rowLabel,
  colLabel,
  isPriceCol,
  isPriceRow,
  min,
  max,
  title,
  price,
  cost,
  prod,
  type,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  function formatCurrencyAndPreserveSign(valor) {
    // Formata o valor usando utilCurrencyFormatter
    const formatted = util.methods.utilCurrencyFormatter(valor);

    // Extrai a parte numérica formatada, mantendo o sinal negativo se existir
    const isNegative = valor < 0;
    const numericPart = formatted.split('$')[1];
    return isNegative ? `-${numericPart}` : `${numericPart}`;
  }

  // Inverter os resultados e os rótulos para a visualização de gráfico/matriz
  const resultadosInvertidos = Array.isArray(resultados)
    ? [...resultados].reverse()
    : [];

  return (
    <Grid
      container
      xs={12}
      display="flex"
      justifyContent="center"
      alignItems="center"
      margin="4rem 0"
      padding="4rem 0"
      sx={{
        border: `2px solid ${theme.palette.grey[700]}`,
        boxShadow: `2px 2px 66px -7px rgba(156,39,176,0.53)`,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          margin: 1,
        }}
      >
        {type === 'custo'
          ? 'Cenário 1 de 3'
          : type === 'preco'
          ? 'Cenário 2 de 3'
          : 'Cenário 3 de 3'}
      </Typography>
      {/* Tabela principal */}
      <TableContainer component={Paper}>
        <Typography
          variant="h6"
          sx={{ textAlign: 'center' }}
          mb={4}
          ml={isMobile ? 0 : '20%'}
        >
          {title}
        </Typography>
        <Table>
          <Grid container xs={12} display="flex">
            {/* Eixo Y - Rótulos das linhas */}
            <Grid
              container
              xs={12}
              justifyContent="center"
              gap={isMobile ? '1rem' : 0}
            >
              <Grid container xs={2} sm={3} justifyContent="flex-end">
                <Grid
                  container
                  xs={6}
                  sm={4}
                  justifyContent="flex-end"
                  alignContent="center"
                >
                  <Typography
                    variant="caption"
                    textAlign="right"
                    sx={{
                      fontStyle: 'italic',
                      fontSize: isMobile ? '0.5rem' : '0.8rem',
                    }}
                  >
                    {rowText}
                  </Typography>
                </Grid>

                <Grid
                  container
                  xs={6}
                  sm={4}
                  direction="column-reverse"
                  justifyContent="center"
                  mb={5}
                >
                  {rowLabel.map((label, index) => (
                    <Typography
                      display="flex"
                      justifyContent="flex-end"
                      mr={isMobile ? 0 : 2}
                      mt="auto"
                      mb="auto"
                      key={index}
                      sx={{
                        textAlign: 'center',
                        fontStyle: 'italic',
                        fontSize: isMobile ? '0.5rem' : '0.8rem',
                      }}
                    >
                      {isPriceRow
                        ? util.methods
                            .utilCurrencyFormatter(label)
                            .split('$')[1]
                        : label}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
              <TableBody>
                {resultadosInvertidos.map((linha, i) => (
                  <TableRow
                    key={i}
                    sx={{
                      borderTop:
                        i === 0 ? `2px solid ${theme.palette.grey[700]}` : '',
                      borderBottom:
                        i === 4 ? `2px solid ${theme.palette.grey[700]}` : '',
                      borderRight: `2px solid ${theme.palette.grey[700]}`,
                      borderLeft: `2px solid ${theme.palette.grey[700]}`,
                    }}
                  >
                    {linha.map((valor, j) => (
                      <TableCell
                        key={j}
                        style={{
                          backgroundColor: useCellColor(valor, min, max),
                          fontSize: isMobile ? '0.5rem' : '1rem',
                          padding: isMobile ? '0.5rem 0.2rem' : '1rem',
                          border:
                            i === 2 && j === 2
                              ? `4px solid ${theme.palette.grey[900]}`
                              : '',
                          borderTop:
                            (i === 1 || i === 4) && j > 0 && j < 4
                              ? `3px solid ${theme.palette.grey[800]}`
                              : i === 2 && j === 2
                              ? `4px solid ${theme.palette.grey[900]}`
                              : '',
                          borderRight:
                            i > 0 && i < 4 && (j === 0 || j === 3)
                              ? `3px solid ${theme.palette.grey[800]}`
                              : i === 2 && j === 2
                              ? `4px solid ${theme.palette.grey[900]}`
                              : '',
                        }}
                      >
                        {/* {(Math.round(valor * 100) / 100).toFixed(2).replace('.', ',')} */}
                        {formatCurrencyAndPreserveSign(valor)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                <TableRow>
                  {/* Eixo X - Rótulos das colunas */}
                  {colLabel.map((label, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        fontSize: isMobile ? '0.5rem' : '1rem',
                        padding: isMobile ? '1rem 0' : '1rem',
                      }}
                    >
                      {isPriceCol
                        ? util.methods
                            .utilCurrencyFormatter(label)
                            .split('$')[1]
                        : label}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow></TableRow>
              </TableBody>
              <Grid
                component={'div'}
                container
                display="flex"
                xs={12}
                justifyContent="center"
                mt={2}
                mb={2}
              >
                <Typography
                  variant="caption"
                  textAlign="center"
                  fontStyle="italic"
                >
                  {colText}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Table>
      </TableContainer>

      <Grid xs={12} padding={2}>
        {getAssessment(price, cost, prod, type)}
      </Grid>
      {/* Legenda ou qualquer outra informação adicional pode ser colocada aqui */}
    </Grid>
  );
};

export default Resultado;
