/* eslint-disable react/prop-types */
import { useTheme } from '@emotion/react';
import { Slider } from '@mui/material';

/**
 *@description Material UI Slider according with the type (default is number and 'PRICE' for currency)
 */
// eslint-disable-next-line react/prop-types
const UISlider = ({
  initialValue,
  type,
  step,
  min,
  max,
  onChangeFn,
  marks,
}) => {
  const value = initialValue || null;
  const theme = useTheme();
  return (
    <Slider
      value={type === 'PRICE' ? value?.toFixed(2) : value}
      onChange={(e, newValue) => onChangeFn(newValue)}
      step={step}
      min={min}
      marks={marks}
      max={max}
      sx={{
        color: theme.palette.primary[900],
      }}
    />
  );
};

export default UISlider;
