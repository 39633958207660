import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Navbar from 'components/Navbar';

const Layout = () => {
  return (
    <Grid container zeroMinWidth columns={12}>
      <Navbar />
      <Outlet />
    </Grid>
  );
};

export default Layout;
