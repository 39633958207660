/* eslint-disable react/prop-types */
import React from 'react';
import { Button, useTheme, Grid, Typography } from '@mui/material';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CurrencyInput from 'react-currency-input-field';
import util from 'helpers/util';

const CalcCustoForm = ({ custo, setCusto, close }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [novoCusto, setNovoCusto] = React.useState(null);
  const [adubBase, setAdubBase] = React.useState(400);
  const [adubNitro, setAdubNitro] = React.useState(250);
  const [calcario, setCalcario] = React.useState(0);
  const [combustiveis, setCombustiveis] = React.useState(150);
  const [defensivos, setDefensivos] = React.useState(100);
  const [sementes, setSementes] = React.useState(100);
  const [mecanizacao, setMecanizacao] = React.useState(150);
  const [outros, setOutros] = React.useState(0);

  const handleClickOpen = () => {
    open === false ? setOpen(true) : setOpen(false);
  };

  function calcular() {
    setNovoCusto(
      adubBase +
        adubNitro +
        calcario +
        combustiveis +
        defensivos +
        sementes +
        mecanizacao +
        outros,
    );
  }
  function cancelar() {
    setNovoCusto(null);
  }
  function confirmar() {
    setCusto(parseFloat(novoCusto));
    close();
  }

  return (
    <Grid container xs={12} border="1px solid grey">
      <Button
        href="#"
        variant="text"
        onClick={handleClickOpen}
        color="secondary"
      >
        Deseja calcular o seu Custo? <ArrowDropDownIcon />
      </Button>

      {open && (
        <Grid container xs={12} justifyContent="space-between">
          <Grid container xs={12}>
            <Grid container xs={6} mt={2}>
              <label>Adubação de base: (R$) </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                id="preco"
                name="preco"
                defaultValue={adubBase}
                decimalScale={2}
                decimalSeparator=","
                groupSeparator="."
                onValueChange={(value) => setAdubBase(parseFloat(value))}
              />
            </Grid>

            <Grid container xs={6} mt={2}>
              <label>Adubação Nitrogenada: (R$) </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                id="preco"
                name="preco"
                defaultValue={adubNitro}
                decimalScale={2}
                decimalSeparator=","
                groupSeparator="."
                onValueChange={(value) => setAdubNitro(parseFloat(value))}
              />
            </Grid>

            <Grid container xs={6} mt={2}>
              <label>Calcário: (R$) </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                id="preco"
                name="preco"
                defaultValue={calcario}
                decimalScale={2}
                decimalSeparator=","
                groupSeparator="."
                onValueChange={(value) => setCalcario(parseFloat(value))}
              />
            </Grid>

            <Grid container xs={6} mt={2}>
              <label>Combustíveis: (R$) </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                id="preco"
                name="preco"
                defaultValue={combustiveis}
                decimalScale={2}
                decimalSeparator=","
                groupSeparator="."
                onValueChange={(value) => setCombustiveis(parseFloat(value))}
              />
            </Grid>

            <Grid container xs={6} mt={2}>
              <label>Defensivos Agrículas: (R$) </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                id="preco"
                name="preco"
                defaultValue={defensivos}
                decimalScale={2}
                decimalSeparator=","
                groupSeparator="."
                onValueChange={(value) => setDefensivos(parseFloat(value))}
              />
            </Grid>

            <Grid container xs={6} mt={2}>
              <label>Sementes: (R$) </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                id="preco"
                name="preco"
                defaultValue={sementes}
                decimalScale={2}
                decimalSeparator=","
                groupSeparator="."
                onValueChange={(value) => setSementes(parseFloat(value))}
              />
            </Grid>

            <Grid container xs={6} mt={2}>
              <label>Serviços Mecanizados: (R$) </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                id="preco"
                name="preco"
                defaultValue={mecanizacao}
                decimalScale={2}
                decimalSeparator=","
                groupSeparator="."
                onValueChange={(value) => setMecanizacao(parseFloat(value))}
              />
            </Grid>

            <Grid container xs={6} mt={2}>
              <label>Outros: (R$) </label>
            </Grid>
            <Grid container xs={6} mt={2}>
              <CurrencyInput
                style={{
                  padding: '0.5em',
                  border: '1px solid #ccc',
                  borderRadius: '3px',
                  width: '100%',
                }}
                id="preco"
                name="preco"
                defaultValue={outros}
                decimalScale={2}
                decimalSeparator=","
                groupSeparator="."
                onValueChange={(value) => setOutros(parseFloat(value))}
              />
            </Grid>

            {novoCusto && (
              <Grid>
                <Typography>
                  O seu custo atualmente é de{' '}
                  <Typography
                    sx={{
                      display: 'inline-block',
                      color: theme.palette.primary.main,
                    }}
                  >
                    {util.methods.utilCurrencyFormatter(novoCusto)}
                  </Typography>{' '}
                  . Confirma alteração?
                </Typography>
                <Button onClick={cancelar} autoFocus>
                  Cancelar
                </Button>
                <Button onClick={confirmar} autoFocus>
                  Confirmar
                </Button>
              </Grid>
            )}

            <Grid xs={12} padding="1rem 1rem">
              <Button
                onClick={calcular}
                variant="contained"
                sx={{
                  width: '100%',
                  backgroundColor: theme.palette.grey[700],
                  color: '#ffffff',
                }}
              >
                <CalculateRoundedIcon /> Calcular o custo por hectare
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CalcCustoForm;
