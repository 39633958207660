import { useTheme } from '@emotion/react';
import { BuildRounded } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import BtnPrimary from 'components/BtnPrimary';
import UISlider from 'components/Slider';
import { ConfigContext } from 'context';
import util from 'helpers/util';
import * as React from 'react';

const Configurations = () => {
  const theme = useTheme();
  const defaultConfig = {
    intervaloCusto: 150,
    intervaloPreco: 1,
    intervaloProdutividade: 100,
  };
  const { config, isConfigOpen, toggleConfigOpen, recordConfig, setConfig } =
    React.useContext(ConfigContext);
  const [intervaloCusto, setIntervaloCusto] = React.useState(
    config?.intervaloCusto
      ? config.intervaloCusto
      : defaultConfig.intervaloCusto,
  );
  const [intervaloPreco, setIntervaloPreco] = React.useState(
    config?.intervaloPreco
      ? config.intervaloPreco
      : defaultConfig.intervaloPreco,
  );
  const [intervaloProdutividade, setIntervaloProdutividade] = React.useState(
    config?.intervaloProdutividade
      ? config.intervaloProdutividade
      : defaultConfig.intervaloProdutividade,
  );
  const onSubmit = () => {
    recordConfig({ intervaloCusto, intervaloPreco, intervaloProdutividade });
    setConfig({ intervaloCusto, intervaloPreco, intervaloProdutividade });
  };
  return (
    <Dialog
      open={isConfigOpen}
      onClose={toggleConfigOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        display="flex"
        justifyContent="center"
        gap={1}
        alignItems="center"
      >
        <BuildRounded />
        <Typography variant="h5">Configurações</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid item xs={12} padding="1rem 1rem">
            <label>
              Intervalos de custo (R$/hectare):{' '}
              <Typography variant="caption" color={theme.palette.primary.dark}>
                {util.methods.utilCurrencyFormatter(intervaloCusto)}
              </Typography>
            </label>
            <UISlider
              initialValue={intervaloCusto}
              step={50}
              min={0}
              max={300}
              onChangeFn={setIntervaloCusto}
              marks={[
                {
                  value: 0,
                  label: 'R$ 0',
                },
                {
                  value: 100,
                  label: 'R$ 100',
                },
                {
                  value: 200,
                  label: 'R$ 200',
                },
                {
                  value: 300,
                  label: 'R$ 300',
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} padding="1rem 1rem">
            <label>
              Intervalos de preço (R$/kg vivo):{' '}
              <Typography variant="caption" color={theme.palette.primary.dark}>
                {util.methods.utilCurrencyFormatter(intervaloPreco)}
              </Typography>
            </label>
            <UISlider
              initialValue={intervaloPreco}
              step={0.5}
              min={0}
              max={2}
              type="PRICE"
              onChangeFn={setIntervaloPreco}
              marks={[
                {
                  value: 0,
                  label: 'R$ 0',
                },
                {
                  value: 1,
                  label: 'R$ 1,00',
                },
                {
                  value: 2,
                  label: 'R$ 1,50',
                },
              ]}
            />
          </Grid>
          <Grid xs={12} padding="1rem 1rem">
            <label>
              Intervalos de produtividade (kg):{' '}
              <Typography variant="caption" color={theme.palette.primary.dark}>
                {intervaloProdutividade}
              </Typography>
            </label>
            <UISlider
              initialValue={intervaloProdutividade}
              step={50}
              min={0}
              max={200}
              onChangeFn={setIntervaloProdutividade}
              marks={[
                {
                  value: 0,
                  label: '0',
                },
                {
                  value: 50,
                  label: '50 kg',
                },
                {
                  value: 150,
                  label: '150 kg',
                },
                {
                  value: 200,
                  label: '200 kg',
                },
              ]}
            />
          </Grid>
          <BtnPrimary onClick={onSubmit} text="Gravar Configurações" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleConfigOpen} autoFocus>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Configurations;
