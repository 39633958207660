import { Grid, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Grid item xs={12} justifyContent="center" mt={2} mb={4}>
      <Typography variant="h6" textAlign="center">
        Embrapa Pecuária Sul
      </Typography>
      <Typography variant="body2" textAlign="center">
        Rodovia BR-153, Km 632,9 Vila Industrial,
        <br />
        Zona Rural, Caixa Postal 242, CEP: 96401-970,
        <br />
        Bagé, RS
        <br />
        Fone: (53) 3240-4650
        <br />
        <a href="www.embrapa.br/pecuaria-sul">www.embrapa.br/pecuaria-sul</a>
        <br />
        <a href="www.embrapa.br/fale-conosco/sac/">
          www.embrapa.br/fale-conosco/sac/
        </a>
        <br />
      </Typography>
    </Grid>
  );
};

export default Footer;
