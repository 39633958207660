import { useState, useContext } from 'react';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import { Button, useTheme, Typography, Grid } from '@mui/material';
import styled from 'styled-components';
import embrapa from 'assets/embrapa.png';
import Resultado from 'components/Resultado';
import util from 'helpers/util';
/* import html2canvas from 'html2canvas'; */
import jsPDF from 'jspdf';
import CurrencyInput from 'react-currency-input-field';
import Footer from 'components/Footer';
import publication from 'assets/publicação embrapa snippet.png';
import VisaoGeral from 'components/VisaoGeral';
import DescCenarios from 'components/DescCenarios';
import AjudaProdutividade from 'components/AjudaProdutividade';
import AjudaCusto from 'components/AjudaCusto';
import DownloadResult from 'components/DownloadResult';
import Configurations from 'sections/Configurations';
import { ConfigContext } from 'context';

const InputField = styled.input`
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
`;

const Calculator = () => {
  const theme = useTheme();
  const { config } = useContext(ConfigContext);
  const defaultConfig = {
    intervaloCusto: 150,
    intervaloPreco: 1,
    intervaloProdutividade: 100,
  };
  const intervaloCusto = config?.intervaloCusto || defaultConfig.intervaloCusto;
  const intervaloPreco = config?.intervaloPreco || defaultConfig.intervaloPreco;
  const intervaloProdutividade =
    config?.intervaloProdutividade || defaultConfig.intervaloProdutividade;
  const [custo, setCusto] = useState(1150.0);
  const [custos, setCustos] = useState([]);
  const [preco, setPreco] = useState(7.5);
  const [precos, setPrecos] = useState([]);
  const [produtividade, setProdutividade] = useState(300);
  const [produtividades, setProdutividades] = useState([]);
  /* const [intervaloCusto, setIntervaloCusto] = useState(150);
  const [intervaloPreco, setIntervaloPreco] = useState(1);
  const [intervaloProdutividade, setIntervaloProdutividade] = useState(100); */
  const [resultados, setResultados] = useState(null);
  const [sortedResults, setSortedResults] = useState(null);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  const sortResults = (resultados) => {
    setSortedResults(
      resultados.sort((a, b) => {
        return a - b;
      }),
    );
  };

  const calcular = () => {
    const precos = [
      preco - 2 * intervaloPreco,
      preco - intervaloPreco,
      preco,
      preco + intervaloPreco,
      preco + 2 * intervaloPreco,
    ];

    const produtividades = [
      produtividade - 2 * intervaloProdutividade,
      produtividade - intervaloProdutividade,
      produtividade,
      produtividade + intervaloProdutividade,
      produtividade + 2 * intervaloProdutividade,
    ];

    const custos = [
      custo - 2 * intervaloCusto,
      custo - intervaloCusto,
      custo,
      custo + intervaloCusto,
      custo + 2 * intervaloCusto,
    ];

    setPrecos(precos);
    setProdutividades(produtividades);
    setCustos(custos);

    const variacaoPrecoProd = precos.map((preco) => {
      return produtividades.map((produtividade) => {
        return produtividade * preco - custo;
      });
    });
    const variacaoPrecoCusto = precos.map((preco) => {
      return custos.map((custo) => {
        return produtividade * preco - custo;
      });
    });
    const variacaoProdCusto = custos.map((custo) => {
      return produtividades.map((prod) => {
        return prod * preco - custo;
      });
    });
    const combinedArray = [
      ...variacaoPrecoCusto,
      ...variacaoPrecoProd,
      ...variacaoProdCusto,
    ].flat(2);
    const minValue = Math.min(...combinedArray);
    const maxValue = Math.max(...combinedArray);
    setMinValue(minValue);
    setMaxValue(maxValue);

    setResultados({ variacaoPrecoProd, variacaoPrecoCusto, variacaoProdCusto });
    const ResultsArray = Object.keys(combinedArray).map(
      (key) => combinedArray[key],
    );
    sortResults(ResultsArray);
    generatePdf();

    const resultElm = document.getElementById('result');
    resultElm.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  const averageProfit = preco * produtividade - custo;

  // const resultDoc = document.getElementById('result')

  function generatePdf() {
    /* html2canvas(resultDoc)
  .then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF();
    pdf.addImage(imgData, 'PNG', 0, 0);
    pdf.save("simulacao_capim_sudao.pdf");
  }) */

    // eslint-disable-next-line new-cap
    const pdf = new jsPDF('landscape', 'mm', 'a4');
    /* let width = pdf.internal.pageSize.getWidth(); */
    // resultDoc.style.maxWidth='100%'

    // for now pop-up of not developed yet

    // eslint-disable-next-line no-console
    console.log(pdf);

    // deactivated for now
    /* pdf.html(resultDoc,
     {
      html2canvas: {
        // insert html2canvas options here, e.g.
        justifyContent: 'center',
        scale: 0.35,
        width: 200
    },
   callback: function (doc) {
    doc.save('simulacao_capim_sudao');
   }
}); */
  }

  return (
    <Grid container>
      <Typography
        variant="h5"
        sx={{
          margin: 1,
        }}
      >
        Para calcular preencha os campos abaixo:
      </Typography>
      <Grid container mt={2} justifyContent="space-between">
        <Grid container md={4} alignContent="flex-start">
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            padding="1rem 1rem"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <label>Custo por hectare (R$/hectare): </label>
            <CurrencyInput
              style={{
                padding: '0.5em',
                border: '1px solid #ccc',
                borderRadius: '3px',
                width: '100%',
              }}
              id="custo"
              name="custo"
              defaultValue={custo}
              value={custo}
              decimalScale={2}
              decimalSeparator=","
              groupSeparator="."
              onValueChange={(value) => setCusto(parseFloat(value))}
            />
          </Grid>
          <Configurations />

          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            padding="1rem 1rem"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <label>Preco de comercialização (R$/kg vivo):</label>
            <CurrencyInput
              style={{
                padding: '0.5em',
                border: '1px solid #ccc',
                borderRadius: '3px',
                width: '100%',
              }}
              id="preco"
              name="preco"
              defaultValue={preco}
              decimalScale={2}
              decimalSeparator=","
              groupSeparator="."
              onValueChange={(value) => setPreco(parseFloat(value))}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            padding="1rem 1rem"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <label>Produtividade (kg peso vivo/hectare): </label>
            <InputField
              type={'number'}
              value={produtividade}
              onChange={(e) =>
                setProdutividade(parseFloat(e.target.value.replace('-', '')))
              }
            />
          </Grid>
          <Grid xs={12} padding="1rem 1rem">
            <Button
              onClick={calcular}
              variant="contained"
              sx={{
                width: '100%',
                backgroundColor: theme.palette.primary.main,
                color: '#ffffff',
              }}
            >
              <CalculateRoundedIcon /> Calcule o lucro por hectare
            </Button>
          </Grid>

          <Grid xs={12} textAlign="center">
            <AjudaCusto custo={custo} setCusto={setCusto} />
          </Grid>
          <Grid xs={12} textAlign="center">
            <AjudaProdutividade
              produtividade={produtividade}
              setProdutividade={setProdutividade}
            />
          </Grid>
          <Grid
            xs={12}
            mt={4}
            mb={4}
            target="_blank"
            component="a"
            href="https://ainfo.cnptia.embrapa.br/digital/bitstream/item/222019/1/Sudao-3.pdf"
          >
            <Typography
              variant="subtitle1"
              textAlign="center"
              color={theme.palette.primary.main}
            >
              {' '}
              Clique aqui ou na imagem para acessar a publicação.
            </Typography>
            <Grid container xs={12} padding={2}>
              <Grid
                item
                xs={6}
                component="img"
                borderRadius={1}
                maxWidth="50%"
                width="auto"
                height="100%"
                src={publication}
                alt="Publicação Impactio Bioeconômico Capim Sudão"
              ></Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} lg={8} justifyContent="center" id="result">
          <Typography variant="h4" component="h4" sx={{ textAlign: 'center' }}>
            Lucro Bruto* (R$/hectare)
          </Typography>
          {resultados && (
            <Grid xs={12} mt={1}>
              <Grid container xs={12} justifyContent="center">
                <DownloadResult />
              </Grid>
              <VisaoGeral
                minvalue={minValue}
                maxValue={maxValue}
                averageProfit={averageProfit}
              />
              <DescCenarios />
              <Resultado
                title={`Custo da pastagem: ${util.methods.utilCurrencyFormatter(
                  custo,
                )}/hectare`}
                rowText="Preço de comercialização (R$/kg vivo)"
                colText="Produtividade (kg/hectare)"
                isPriceRow={true}
                resultados={resultados.variacaoPrecoProd}
                sortedResults={sortedResults}
                rowLabel={precos}
                colLabel={produtividades}
                max={maxValue}
                min={minValue}
                type="custo"
                price={preco}
                cost={custo}
                prod={produtividade}
              />
              <Resultado
                title={`Preço: ${util.methods.utilCurrencyFormatter(
                  preco,
                )}/Kg peso vivo`}
                rowText="Custo (R$ / hectare)"
                colText="Produtividade (kg/hectare)"
                isPriceRow={true}
                resultados={resultados.variacaoProdCusto}
                sortedResults={sortedResults}
                rowLabel={custos}
                colLabel={produtividades}
                max={maxValue}
                min={minValue}
                type="preco"
                price={preco}
                cost={custo}
                prod={produtividade}
              />
              <Resultado
                title={`Produtividade: ${produtividade} kg peso vivo/hectare`}
                rowText="Preço de comercialização (R$/kg vivo)"
                colText="Custo (R$ / hectare)"
                isPriceRow={true}
                resultados={resultados.variacaoPrecoCusto}
                sortedResults={sortedResults}
                isPriceCol={true}
                rowLabel={precos}
                colLabel={custos}
                max={maxValue}
                min={minValue}
                type="produtividade"
                price={preco}
                cost={custo}
                prod={produtividade}
              />
            </Grid>
          )}
        </Grid>
        <Grid xs={12}>
          <Typography variant="subtitle2" textAlign="center">
            *O lucro bruto é obtido pela subtração entre o Valor da Produção
            (VP) e o Custo Operacional Efetivo (COE).
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" mt={4} mb={4}>
        <img src={embrapa} alt="logo embrapa" width="150px" />
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Calculator;
