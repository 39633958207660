import { useTheme } from '@emotion/react';
import { Button } from '@mui/material';

// eslint-disable-next-line react/prop-types
const BtnPrimary = ({ onClick, text }, other) => {
  const theme = useTheme();
  return (
    <Button
      onClick={onClick}
      variant="contained"
      sx={{
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        color: '#ffffff',
      }}
      autoFocus
    >
      {text}
    </Button>
  );
};

export default BtnPrimary;
