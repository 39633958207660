export default {
  methods: {
    utilRoundDecimal(nDigits, value) {
      const n = parseInt(nDigits);
      let v = parseFloat(value);
      v = v * Math.pow(10, n);
      v = Math.round(v);
      return v / Math.pow(10, n);
    },
    utilFormattedTime(date) {
      if (date === undefined) date = Date();

      const y = date.getFullYear();
      const m = (date.getMonth() + 1).toString().padStart(2, '0');
      const d = date.getDate().toString().padStart(2, '0');
      const h = date.getHours().toString().padStart(2, '0');
      const mi = date.getMinutes().toString().padStart(2, '0');
      const s = date.getSeconds().toString().padStart(2, '0');

      return y + '-' + m + '-' + d + '_' + h + '-' + mi + '-' + s;
    },
    utilExtractYear(date) {
      if (!date || typeof date !== 'string') return 0;

      const array = date.split('-');

      if (
        array.length !== 3 ||
        array[0].length !== 4 ||
        typeof array[0] !== 'string'
      )
        return 0;

      return parseInt(array[0]);
    },
    utilCurrencyFormatter(num) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
      return formatter.format(num);
    },
    utilHexToRgbA(hex, opacity) {
      let c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return (
          'rgba(' +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
          ',' +
          opacity +
          ')'
        );
      }
      throw new Error('Bad Hex');
    },
  },
};
