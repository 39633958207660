/* eslint-disable react/prop-types */
import React from 'react';

const defaultConfig = {
  intervaloCusto: 150,
  intervaloPreco: 1,
  intervaloProdutividade: 100,
};

const recordedConfig = localStorage.getItem('config-sudao');
const initialConfig = recordedConfig || defaultConfig;
const isConfigOpen = false;
export const ConfigContext = React.createContext({
  config: initialConfig,
  isConfigOpen: isConfigOpen,
});

const ConfigProvider = (props) => {
  const [isConfigOpen, setIsConfigOpen] = React.useState(false);
  const [config, setConfig] = React.useState(
    JSON.parse(recordedConfig) || initialConfig,
  );

  function toggleConfigOpen() {
    isConfigOpen === true ? setIsConfigOpen(false) : setIsConfigOpen(true);
  }

  function recordConfig(newConfig) {
    localStorage.setItem('config-sudao', JSON.stringify(newConfig));
  }
  return (
    <ConfigContext.Provider
      value={{
        config,
        isConfigOpen,
        recordConfig,
        toggleConfigOpen,
        setConfig,
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
